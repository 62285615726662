import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  planData: {
    planName: String;
    price: Number;
    per: String;
    catchCopy: String;
    body: Array<String>;
    li: Array<String>;
  };
};

const PlansItem = (props: Props) => {
  return (
    <section className="plans_item">
      <h2 className="plans_item_planName">{props.planData.planName}</h2>
      <h3 className="plans_item_price">
        <span className="plans_item_price_yen">&yen;</span>
        {props.planData.price.toString()}~
      </h3>
      <h4 className="plans_item_per">{props.planData.per}</h4>
      <p className="plans_item_body">{props.planData.catchCopy}</p>
      <ul className="plans_item_ul">
        <li>{props.planData.li[0]}</li>
        <li>{props.planData.li[1]}</li>
      </ul>
      {props.children}
    </section>
  );
};

export default PlansItem;
