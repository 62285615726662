import '../../assets/css/lp.css';

import AddchartIcon from '@mui/icons-material/Addchart';
import GroupsIcon from '@mui/icons-material/Groups';
import ForumIcon from '@mui/icons-material/Forum';
import { ToTop } from '../modules';
import { LPHero } from '../modules';
import 'animate.css';

const Lp = () => {
  type Opt = {
    icon: any;
    title: String;
    body: String;
  };

  const opt: Opt[] = [
    {
      icon: <AddchartIcon fontSize={'large'} />,
      title: '広告に',
      body: 'ランディングページはその性質上、広告にうってつけのコンテンツです。1ページに分かりやすくまとまった情報、商品やサービスに合わせたデザイン、絶妙なタイミングで登場するお問合せフォームやショッピングカートなど、ページへ訪れてきた人にとって最適な体験を提供します。',
    },
    {
      icon: <GroupsIcon fontSize={'large'} />,
      title: '集客に',
      body: 'ランディングページはその性質上、広告にうってつけのコンテンツです。1ページに分かりやすくまとまった情報、商品やサービスに合わせたデザイン、絶妙なタイミングで登場するお問合せフォームやショッピングカートなど、ページへ訪れてきた人にとって最適な体験を提供します。',
    },
    {
      icon: <ForumIcon fontSize={'large'} />,
      title: 'コンバージョンに',
      body: 'ランディングページはその性質上、広告にうってつけのコンテンツです。1ページに分かりやすくまとまった情報、商品やサービスに合わせたデザイン、絶妙なタイミングで登場するお問合せフォームやショッピングカートなど、ページへ訪れてきた人にとって最適な体験を提供します。',
    },
  ];

  return (
    <main id="lp" className="lp">
      <ToTop />
      <LPHero />

      <section className="lpOpt lpSection">
        <h2 className="lpOpt_title">
          Budou designが
          <br />
          最適なLPを制作します
        </h2>
        <div className="flex flex-between flex-wrap">
          {opt.map((value, index) => {
            return (
              <div className="lpOpt_box neumo" key={index.toString()}>
                {value.icon}
                <h2 className="lpOpt_box_title">{value.title}</h2>
                <p className="lpOpt_box_body">{value.body}</p>
              </div>
            );
          })}
        </div>
      </section>
      <section className="lpTypeText">
        <h2 className="lpTypeText_title">用意いただくのは文章と画像だけ</h2>
        <p className="lpTypeText_body">
          多くの人に自分のプロダクトを知ってもらうため、ランディングページを作るには、多少の技術と知識が必要になります。
          <br />
          まずはデザインをおこして、デザインをもとにhtmlを書いて、cssでレイアウトをして、必要に応じてjavascriptも書いて、さらにメールフォームが必要であればサーバーサイドでの処理も書いて。
        </p>
        <p className="lpTypeText_body">
          これらの作業は慣れていないと、時間もかかりなかなか大変な作業になります。
          <br />
          Budou
          designは文章と商品などの必要な画像を数点用意いただければ、最適なランディングページを納品まで1週間程度で制作いたします。
        </p>
      </section>
      <section className="lpTypeText">
        <h2 className="lpTypeText_title">用意いただく文章について</h2>
        <p className="lpTypeText_body">
          以下のような文章を用意いただくと、より良いランディングページがスムーズに制作でき納期も早くなります
        </p>
        <ul className="lpTypeText_ul">
          <li className="lpTypeText_ul_li">キャッチコピー</li>
          <li className="lpTypeText_ul_li">商品を使うことによるメリット</li>
          <li className="lpTypeText_ul_li">商品の特性</li>
          <li className="lpTypeText_ul_li">商品の価値</li>
          <li className="lpTypeText_ul_li">他社製品との違い</li>
          <li className="lpTypeText_ul_li">商品の使いかた</li>
          <li className="lpTypeText_ul_li">商品の価格</li>
          <li className="lpTypeText_ul_li">商品のレビュー</li>
          <li className="lpTypeText_ul_li">連絡・お問合せ先</li>
          <li className="lpTypeText_ul_li">よくある質問</li>
        </ul>
      </section>
      <section className="lpTypeText">
        <h2 className="lpTypeText_title">用意いただく画像について</h2>
        <p className="lpTypeText_body">
          無料で使用できる凡庸性の高い画像の用意があります。デザイン前の段階で提示いたしますのでご利用の場合は相談ください
        </p>
        <ul className="lpTypeText_ul">
          <li className="lpTypeText_ul_li">なるべく解像度が高い画像</li>
          <li className="lpTypeText_ul_li">
            なるべくサイズの大きな商品写真を数点
          </li>
          <li className="lpTypeText_ul_li">会社やしょうひんのロゴ画像</li>
        </ul>
        <p className="lpTypeText_body">
          その他に、使用したい写真や画像、キャラクターイラストなどがありましたらデザインに組み込みます。
        </p>
      </section>
      <section className="lpSection">
        <div className="lpTypeNeumo">
          <h2 className="lpTypeNeumo_title">制作の流れ</h2>
        </div>
      </section>
      <section>
        <h2 className="lpTypeNeumo_title">Q &amp; A</h2>
      </section>
      <section className="responsive">
        <h2 className="responsive_title">
          どんな画面サイズでも最適なレイアウトです
        </h2>
      </section>
    </main>
  );
};

export default Lp;
