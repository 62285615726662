import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  children?: ReactNode;
  planData: {
    planName: String;
    price: Number;
    per: String;
    catchCopy: String;
    body: Array<String>;
    li: Array<String>;
  };
};

const PlansItem = (props: Props) => {
  return (
    <section className="plan">
      <div className="plan_box">
        <h2 className="plan_box_title">{props.planData.planName}</h2>
        <p className="plan_box_catchCopy">{props.planData.catchCopy}</p>
        <p className="plan_box_price">¥{props.planData.price.toString()}~</p>
        <div className="plan_box_body">
          {props.planData.body.map((value, index) => {
            return (
              <p key={index.toString()} className="plan_box_body_text">
                {value}
              </p>
            );
          })}
        </div>
        <Link
          to={`/contact/${props.planData.planName}`}
          className="plans_toContact"
        >
          ご依頼はこちらから
        </Link>
      </div>
      {props.children}
    </section>
  );
};

export default PlansItem;
