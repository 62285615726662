import Logo from '../../assets/images/logoWhite.svg';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect } from 'react';

const Header = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to('.header_bar', {
      duration: 0.5,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      scrollTrigger: {
        trigger: '.headerTrigger', //アニメーションが始まるトリガーとなる要素
        start: 'top center', //アニメーションが始まる位置を指定
        // toggleActions: 'play none reverse none',
      },
    });
  });
  return (
    <header className="header flex flex-center">
      <Link to={'/'} className="header_main flex flex-center">
        <img className="header_main_logo" src={Logo} alt="logo" />
        <h1 className="header_main_title">budou design</h1>
      </Link>
      <Link to="/contact" className="header_menu">
        Contact
      </Link>
      <div className="header_bar"></div>
    </header>
  );
};
export default Header;
