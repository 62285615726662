import TextField from '@mui/material/TextField';
import { ChangeEvent, useState } from 'react';
import { ToTop } from '../modules';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Contact = () => {
  const [inputName, setInputName] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputMessage, setInputMessage] = useState('');
  const { id } = useParams();

  useEffect(() => {
    if (
      id === 'ライトプラン' ||
      id === 'スタンダードプラン' ||
      id === 'プラチナプラン' ||
      id === 'メンテナンス' ||
      id === 'Wordpressテーマ' ||
      id === 'ランディングページ' ||
      id === 'スマートフォン対応' ||
      id === 'バナー制作' ||
      id === 'ロゴ制作' ||
      id === 'ECサイト構築'
    ) {
      setInputMessage(id + 'のお問い合わせ');
    } else {
      setInputMessage('');
    }
    console.log(id);
  }, []);

  const handleName = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setInputName(e.target.value);
  };

  const handleEmail = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setInputEmail(e.target.value);
  };

  const handleMessage = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setInputMessage(e.target.value);
  };

  const payload = {
    text:
      'お問合せがありました\n' +
      'お名前: ' +
      inputName +
      '\n' +
      'email: ' +
      inputEmail +
      '\n' +
      'お問合せ内容: \n' +
      inputMessage +
      '\n',
  };

  const submit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const slackUrl =
      'https://hooks.slack.com/services/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';
    fetch(slackUrl, {
      method: 'post',
      body: JSON.stringify(payload),
    })
      .then(() => {
        alert(
          '送信が完了しました。\n入力いただいたEメールアドレスに1営業日以内に返信をしたしますのでしばらくお待ちください。'
        );
        setInputName('');
        setInputEmail('');
        setInputMessage('');
      })
      .catch(() => {
        alert('送信が失敗しました。');
      });
  };

  return (
    <section className="container">
      <ToTop></ToTop>

      <form onSubmit={submit} className="contact">
        <h2 className="contact_title">Contact</h2>
        <div className="contact_box">
          <TextField
            label="お名前"
            required
            variant="outlined"
            value={inputName}
            className="contact_box_textField"
            onChange={(e) => {
              handleName(e);
            }}
          />
        </div>
        <div className="contact_box">
          <TextField
            label="Eメール"
            required
            variant="outlined"
            value={inputEmail}
            className="contact_box_textField"
            type="email"
            onChange={(e) => {
              handleEmail(e);
            }}
          />
        </div>
        <div className="contact_box">
          <TextField
            id="outlined-textarea"
            label="お問合せ内容"
            className="contact_box_textField"
            value={inputMessage}
            multiline
            onChange={(e) => {
              handleMessage(e);
            }}
          />
        </div>
        <input type="submit" value="送 信" className="contact_submit" />
      </form>
    </section>
  );
};

export default Contact;
