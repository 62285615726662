import i1 from '../../../assets/images/ika.png';

const Ika = () => {
  return (
    <section className="ika">
      <a href="https://store.line.me/stickershop/author/547401/ja">
        <img src={i1} alt="イカのLINEスタンプ販売中" className="ika_img" />
      </a>
    </section>
  );
};

export default Ika;
