import React, { useRef, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home, Privacypolicy, Lp, Contact } from './components/pages';
import { Header, Footer } from './components/modules';
import HeroMovie from './assets/movie/a7.mp4';
import './assets/css/style.css';

const App = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    videoRef.current?.play();
  }, []);
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path={`/`} element={<Home />} />
        <Route path={`/privacypolicy/`} element={<Privacypolicy />} />
        <Route path={`/contact/`} element={<Contact />} />
        <Route path={`/contact/:id`} element={<Contact />} />
        <Route path={`/lp/`} element={<Lp />} />
      </Routes>
      <React.StrictMode>
        <video playsInline autoPlay muted loop ref={videoRef} id="heroImg">
          <source src={HeroMovie} type="video/mp4" />
          <p>Your browser doesn't support HTML5 video.</p>
        </video>
      </React.StrictMode>
      <p id="heroGeo">
        Picture's Location:
        <a
          href="https://www.google.co.jp/maps/place/35%C2%B039'24.4%22N+139%C2%B055'07.9%22E/@35.65679,139.9175796,17z/data=!3m1!4b1!4m6!3m5!1s0x0:0x591492374278aa03!7e2!8m2!3d35.6567902!4d139.918872?hl=ja"
          id="heroGeo_link"
        >
          35.656778N, 139.918861E
        </a>
      </p>
      {/* <ContactLink /> */}
      <Footer />
    </BrowserRouter>
  );
};

export default App;
