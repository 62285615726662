import { PlansItem, Plan } from '../';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import LeftAnimationTitle from '../LeftAnimationTitle';

const Plans = () => {
  type PlanData = {
    planName: String;
    price: Number;
    per: String;
    catchCopy: String;
    body: Array<String>;
    li: Array<String>;
  };

  const [toPlanData, setToPlanData] = useState<PlanData>({
    planName: 'test',
    price: 100,
    per: '10',
    catchCopy: 'catchCopy',
    body: ['body'],
    li: ['test1', 'test2'],
  });
  const [visible, setVisible] = useState(false);

  const planData: PlanData[] = [
    {
      planName: 'ライトプラン',
      price: 170000,
      per: '1サイト',
      catchCopy: '1~5ページほどの小規模サイトを制作します',
      body: [
        '5ページ程度の小規模コーポレートサイトやサービス・活動の紹介などに、よくご利用いただいているプランです。お問合せフォームやスマートフォン用レイアウトにも料金内で対応いたします。',
        '最大¥280000で5ページまでのwebサイトを制作いたします。',
        '1. トップページ',
        '2. お問合せ',
        '3. 会社概要',
        '4. アクセス',
        '5. プライバシーポリシー',
        'などにご利用ください。',
      ],
      li: ['サービスの簡単な紹介', '事業用の簡単なサイト'],
    },
    {
      planName: 'スタンダードプラン',
      price: 330000,
      per: '1サイト',
      body: [
        'サービスや商品を数点紹介したりコンテンツ別にページを分けたサイトを作りたい方におすすめのプランです。',
        '最大¥520000で19ページまでのwebサイトを制作いたします。',
        '1. トップページ',
        '2. お問合せ',
        '3. 会社概要',
        '4. アクセス',
        '5. プライバシーポリシー',
        '6. サービスの詳細',
        '7. IRページ',
        'その他、必要に応じたコンテンツなどにご利用ください。',
      ],
      catchCopy: '6~19ページ程度のサイトを制作します。',
      li: ['会社概要などのサイト', 'サービスの紹介など'],
    },
    {
      planName: 'プラチナプラン',
      price: 650000,
      per: '1サイト',
      body: [
        'サービスや商品などコンテンツ量の多いサイトにおすすめのプランです。サイトの構成によってはWordpressなど他のプランの方がコストパフォーマンスの良い場合もありますので、その場合はこちらより他プランのご案内をいたします。',
      ],
      catchCopy: '20ページ以上の大規模サイトを制作します',
      li: ['情報量の多いサイト', '商品など多数紹介したい'],
    },
    {
      planName: 'メンテナンス',
      price: 3500,
      per: '月額',
      body: [
        'サイトの更新や修正、バグの修正、セキュリティ対策など、サイトの機能性やセキュリティを維持し、ユーザーにとって使いやすいサイトを提供するために定期的なメンテナンスをおすすめしています',
        'サイトの更新や修正により、検索エンジンのランキングを上げることができる場合もあります。',
        'サーバーやドメインの管理などもお任せいただけます。',
        '大幅な更新が必要な場合は別途料金が発生しますのでご相談ください。',
      ],
      catchCopy: '制作後のメンテナンスお任せいただけます',
      li: ['事業に集中したい', '自分で更新は面倒'],
    },
    {
      planName: 'Wordpressテーマ',
      price: 350000,
      per: '1サイト',
      body: [
        'WordPressにはシンプル操作で記事やページをを作成、編集、公開することができます。画像の管理やSEO最適化、複数人でのサイトを管理する場合のユーザー管理機能なども標準で付属しています。',
        'WordPressは、個人のブログから大規模なニュースサイト、電子商取引サイト、政府や非営利団体などのサイトにも使用されており、機能を追加するための大量のプラグインも無料で提供されています。汎用性が高く柔軟性があるため、様々なサイトに使用することができます。',
        'budou designではサービスに合わせたWordpress用のオリジナルデザインを制作しています。納品後はSNSを更新するような感覚で、ご自身でサイトの管理や更新が可能となりますので管理などは自社やご自身でやっていきたい場合はこちらのプランがおすすめです。',
      ],
      catchCopy: 'Wordpressのオリジナルテーマ制作',
      li: ['自身でサイト更新したい', 'オリジナルのブログ'],
    },
    {
      planName: 'ランディングページ',
      price: 65000,
      per: '1ページ',
      body: [
        'ランディングページは、特定のターゲットに向けた広告やマーケティング活動の一環として使用されコンバージョンに効果的なWebコンテンツです。',
        '当プランではビジネスに最適なランディングページを作成いたします。高いコンバージョン率を実現するデザインとコピーを提供します。',
        'デザインのトレンドやサービスの更新に合わせて、今後のメンテナンスが必要な場合はオプションでお引き受けいたしますのでご相談ください。',
      ],
      catchCopy: 'LPのデザインとコーディングをします',
      li: ['広告用ページが欲しい', 'サービスや商品のPR'],
    },
    {
      planName: 'スマートフォン対応',
      price: 65000,
      per: '1サイト',
      body: [
        '現在のサイトがスマーフォンやiPadなどに対応していなくて、レイアウトが崩れてしまうなどの問題を解決いたします。',
        'スマートフォンユーザーが増えている今、Webサイトのスマートフォン対応は必須です。当プランではすべてのデバイスに対応したレスポンシブデザインを提供しています。',
        'PCからスマートフォンまで基本的にはどのような画面サイズでも視認性の高いコンテンツをユーザーに届けられるレイアウトを制作いたします。',
      ],
      catchCopy: 'スマートフォン対応サイトにアップデート',
      li: ['スマホで表示が崩れる', 'スマホでも集客したい'],
    },
    {
      planName: 'バナー制作',
      price: 5000,
      per: '1つ',
      body: [
        'トップページやサイドバーなどに表示する広告用のバナーなど各種サイズWebサイトのデザインに合わせて制作いたします。',
        '特定のターゲットに合わせたデザインにも対応しますのでご相談ください。',
        '1. 商品やサービスの宣伝',
        '2. アクセス数やクリック数を増やしたい',
        '3. ブランド力の向上',
      ],
      catchCopy: '各種サイズのバナーを制作します。',
      li: ['広告用のバナーが欲しい', 'クリック率を上げたい'],
    },
    {
      planName: 'ロゴ制作',
      price: 30000,
      per: '1つ',
      body: [
        'コンテンツのイメージに合わせたブランドイメージをより強くアピールするためのロゴの制作をいたします。',
        'デザイントレンドに基いた制作後のフォローアップもいたします。',
      ],
      catchCopy: 'イメージにあったロゴを制作します',
      li: ['お店、サービス用に', '新規事業、商品用に'],
    },
    {
      planName: 'ECサイト構築',
      price: 400000,
      per: '1サイト',
      body: [
        '楽天市場やYahooShop、Shopify、BASEなどに出店を希望の方におすすめのプランです。',
        'Shop全体のデザインと設定、5商品までの商品ページを制作いたします。6商品以上の出品は1商品につき¥5000~お引き受けいたします。',
      ],
      catchCopy: 'Shopify、BASE、楽天などの設定やデザイン',
      li: ['ネットショップ始めたい', '設定が複雑でわからない'],
    },
  ];

  return (
    <div className="container background-gray container-shadow">
      <h1 className="container_title">Plans</h1>
      <LeftAnimationTitle
        text="さまざまなプランをご用意しています"
        id="planAnimation"
        x1={-1000}
        x2={0}
      ></LeftAnimationTitle>
      {/* <p className="container_subTitle">
        <span className="container_subTitle_span">
          Webサイトのああしたい、こうしたい、様々な要望に対応できます
        </span>
      </p> */}
      <section className="plans container-l homeSection">
        <div className="flex flex-wrap">
          {planData.map((value, index) => {
            return (
              <PlansItem key={index.toString()} planData={value}>
                <button
                  className="plans_item_button"
                  onClick={() => {
                    setToPlanData(value);
                    setVisible(true);
                  }}
                >
                  詳 細
                </button>
              </PlansItem>
            );
          })}
        </div>
      </section>
      {visible && (
        <Plan planData={toPlanData}>
          <div
            className="plan_background"
            onClick={() => {
              setVisible(false);
            }}
          ></div>
        </Plan>
      )}
      <Link to={`/contact`} className="plans_toContact">
        プランにはないご依頼はこちらから
      </Link>
      <div className="budour"></div>
    </div>
  );
};

export default Plans;
