const LPHero = () => {
  return (
    <section className="lpHero lpSection">
      <h1 className="lpHero_title">
        <span className="lpHero_title_l animate__animated animate__fadeInTopLeft">
          L
        </span>
        <span className="lpHero_title_p animate__animated animate__fadeInBottomRight">
          P
        </span>
      </h1>
      <div className="lpHero_content">
        <p className="lpHero_content_text">
          <span className="lpHero_content_text_span">¥ 65,000~ </span>
        </p>
      </div>
      <div className="section_background1"></div>
    </section>
  );
};

export default LPHero;
