import { Plans, Flow, Qa, Ika, ToTop } from '../modules';
const Home = () => {
  return (
    <main className="main" id="main">
      <ToTop />
      <section className="hero flex flex-center" id="firstSection">
        <div>
          <h1 className="hero_title">浦安・市川のWeb制作</h1>
          <p className="hero_body"></p>
        </div>
      </section>
      <section className="secondary flex flex-center headerTrigger">
        <h1 className="secondary_title">
          最適なWebサイトを
          <br />
          <strong className="secondary_title_em">安く</strong>
          <strong className="secondary_title_em">早く</strong>
          <br />
          制作する
          <strong className="secondary_title_strong">budou design</strong>です
        </h1>
      </section>
      <Plans />
      <Flow />
      <Qa />
      <Ika />
    </main>
  );
};
export default Home;
