import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer className="footer flex flex-center flex-wrap">
      <nav className="footer_nav">
        <Link to="/" className="footer_nav_link">
          Home
        </Link>
        {/* <Link to="/lp" className="footer_nav_link">
          Lp
        </Link> */}
        <Link to="/contact" className="footer_nav_link">
          Contact
        </Link>
        <Link to="/privacypolicy" className="footer_nav_link">
          プライバシーポリシー
        </Link>
      </nav>
    </footer>
  );
};
export default Footer;
