import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect } from 'react';

type Props = {
  id: string;
  text: string;
  x1: number;
  x2: number;
};

const RightAnimationTitle = (props: Props) => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo(
      '#' + props.id,
      {
        x: props.x1,
        opacity: 0,
      },
      {
        x: props.x2,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: '#' + props.id,
        },
      }
    );
  });

  return (
    <p className="container_subTitle container_right" id={props.id}>
      <span className="container_subTitle_span">{props.text}</span>
    </p>
  );
};

export default RightAnimationTitle;
