import LeftAnimationTitle from '../LeftAnimationTitle';
type QaItems = {
  q: string;
  a: string;
};
const Qa = () => {
  const qaItems: QaItems[] = [
    {
      q: '納期はどれぐらいですか?',
      a: 'プランや制作の規模によって異なりますが、簡単なHTML/CSSのページでしたら最短で即日の納品も可能です。',
    },
    {
      q: '現在のホームページの再活用はできますか?',
      a: 'リニューアルする場合サービスや商品の画像や説明文などは、そのまま再活用することも多いですが、ホームページ全体のデザインやコードは基本的に1から作ることになります。修正が数カ所程度の場合はデザインやコードをほとんど変えずに修正可能です。',
    },
    {
      q: 'ロゴや画像などの用意は必要ですか？',
      a: 'すでに完成しているロゴや利用したい画像がある場合はデータの提供をお願いしています。ロゴや画像はこちらで制作することも可能ですのでご相談ください。',
    },
    {
      q: 'サイト完成後は自分で更新していきたいのですが可能ですか?',
      a: 'ブログ感覚でページを追加したり更新ができるwordpressのご利用をおすすめいたします。納品後に必要であれば使用方法などの説明もしていますのでご相談ください。',
    },
    {
      q: '一部だけ制作を手伝ってほしいのですが可能ですか?',
      a: 'htmlだけ、cssだけ、ロゴの制作やデザインだけなど一部のみの制作もご利用いただけます。',
    },
    {
      q: 'ホームページの維持費はどのくらいですか?',
      a: 'ご自身で契約していただくサーバとドメインの料金が基本的な維持費となります。簡単なホームページの構成であればサーバーの料金は月額500円~1500円程度(最安で200円以下もあります)、ドメインが年間1500円程です。その他に当社で作成したホームページのメンテナンスや更新などをご利用の場合は月額3500円から承っております。なお当サイトはFirebaseの無料枠のみで維持しており、年間1500円程度のドメイン費用のみで運営しています。今後のFirebaseの対応次第では変動もあり得ますが、現状は年間コスト無料〜3000円程度で運営することも可能です。',
    },
    {
      q: '使用する言語の指定は可能ですか?',
      a: 'サーバーサイドの言語はPHP、Ruby、Webフレームワークはlaravel、ruby on rails、フロントエンドはjavascript、jquery、React、Vue.jsなどからお選びいただけます。特に指定がなければhtmlとcss、サーバーサイドの処理が必要な場合はPHPを使用して制作いたします。各種言語とFirebaseの連携も可能ですのでご相談ください。',
    },
  ];
  return (
    <div className="container background-gray">
      <h1 className="container_title">FAQ</h1>
      <LeftAnimationTitle
        text="budou designに寄せられる、よくある質問"
        id="qaAnimation"
        x1={-1000}
        x2={0}
      ></LeftAnimationTitle>
      <section className="qa container-l homeSection">
        {qaItems.map((value, index) => {
          return (
            <div className="qa_box" key={index.toString()}>
              <p className="qa_box_q flex ">
                <span className="qa_box_q_l">Q</span>
                <span className="qa_box_q_r">{value.q}</span>
              </p>
              <p className="qa_box_a flex">
                <span className="qa_box_a_l">A</span>
                <span className="qa_box_a_r">{value.a}</span>
              </p>
            </div>
          );
        })}
      </section>
      <div className="budour"></div>
    </div>
  );
};

export default Qa;
