import RightAnimationTitle from '../RightAnimationTitle';

type FlowItem = {
  title: string;
  body: string;
};
const Flow = () => {
  const flowItems: FlowItem[] = [
    {
      title: 'ヒアリング',
      body: '作りたいサイトのイメージや、紹介したいサービスや商品、その他理想のWebサイト像をお聞かせください。ヒアリングは直接お会いしての打ち合わせの他、メールやzoomなどでも可能です。イメージや既に完成されたデザインなどがある場合は、デザインや参考となる資料をご提出ください。',
    },
    {
      title: '見積もり・ラフ案提出',
      body: 'ヒアリング内容をもとに見積もりと、簡単なラフ案を提出いたします。見積もり・ラフ案に問題がなければ、この時点で正式なお申し込みをいただきます。',
    },
    {
      title: 'デザイン制作',
      body: 'お申込みをいただいたあと、デザインの制作に入ります。デザインは持ち込みも可能です。デザイン持込の場合はHTML/CSSコーディングプランのご利用をおすすめいたします。',
    },
    {
      title: '開発',
      body: 'Webサイトの開発に入ります。使用可能な言語はHTML・CSS・Javascript(Vue React Jquery)・PHPなどです。使用言語にご指定がない場合は、こちらでサイトに合わせて選択いたします。',
    },
    {
      title: 'テスト',
      body: '開発したファイルを実際にサーバーへアップロードして、スマートフォンやPCなど、どの端末からでもレイアウトの崩れがないか、ボタンの反応や、フォームの送信、その他エラーチェックなどのテストをおこないます。',
    },
    {
      title: '納品',
      body: 'テストに問題がなければ、納品となります。基本的にはここで契約は終了となりますが、budou designで制作したWebサイトにつきましてはメンテナンス（月額2000円〜）なども承っておりますのでご相談ください。',
    },
  ];
  return (
    <div className="container background-white">
      <div className="budoul"></div>
      <h1 className="container_title container_right">Flow</h1>
      <RightAnimationTitle
        text="制作・納品までの基本的な流れと、おおよその期間"
        id="flowAnimation"
        x1={1000}
        x2={0}
      ></RightAnimationTitle>
      <section className="flow container-l homeSection">
        {flowItems.map((value, index) => {
          return (
            <div className="flow_box" key={index.toString()}>
              <h2 className="flow_box_title">
                {index + 1}. {value.title}
              </h2>
              <p className="flow_box_body">{value.body}</p>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default Flow;
